import { useState } from 'react';
import ArrowIcon from '../icons/ArrowIcon';
import { useRouter } from 'next/router';

const Brands = ({ options, handleFilter, code }) => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showMore, setShowMore] = useState(false);
  const { locale } = useRouter();
  return (
    <section>
      <div
        className={`flex items-center justify-between md:mb-3 ${
          open && 'mb-3'
        }`}
      >
        <h3 className='text-sm font-[500] md:text-[20px]'>Brands</h3>
        <ArrowIcon
          onClick={() => setOpen(!open)}
          className={`${
            !open ? (locale === 'en' ? '-rotate-90' : 'rotate-90') : ''
          } cursor-pointer md:hidden`}
        />
      </div>
      <section className={`${open ? 'block' : 'hidden'} md:block`}>
        <ul className='flex flex-wrap items-center gap-x-3 gap-y-2'>
          {options?.slice(0, showMore ? options?.length : 11)?.map((option, idx) => (
            <li
              id={`brand-${option?.key}`}
              key={option?.key || idx}
              className='cursor-pointer rounded-full bg-[#F5F5F5] px-4 py-1.5 text-xs font-[500]'
              onClick={() =>
                handleFilter({
                  code,
                  item: option,
                })
              }
            >
              {option?.Label}
            </li>
          ))}
        </ul>
      </section>
    </section>
  );
};

export default Brands;
