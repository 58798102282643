import { useContext } from 'react';
import { useRouter } from 'next/router';
import { GlobalModalContext } from '@/layout';

import Image from '../Image';
import EyeIcon from '../icons/EyeIcon';
import AddToCart from '../global/buttons/AddToCart';
import AddtoWishlist from '../global/buttons/AddtoWishlist';
import Link from 'next/link';
import { validatePrice } from '@/helpers/product';

const ProductCard = ({ product, isMulti, fromGrid, isFav }) => {
  const { locale } = useRouter();
  const Context = useContext(GlobalModalContext);

  const price = validatePrice(product?.prices_with_tax);

  return (
    <div className={`${fromGrid && 'flex gap-2.5 max-sm:px-px sm:gap-4'}`}>
      <div className='group relative'>
        {/* <span
          className={`absolute start-3 top-3 w-12 rounded-md p-1 text-center text-[10px] text-white sm:text-xs ${
            // If It's New
            i === 1
              ? 'bg-[#68D237]'
              : // If It has discount
                i === 2
                ? 'bg-[#FF8154]'
                : // If it has been sold
                  'bg-[#D6D6D6] font-[500] text-black'
          }`}
        >
          {i === 1 ? 'New' : i === 2 ? '-15%' : 'Sold'}
        </span> */}
        <Link
          href={`/product/${product?.rewrite_url}`}
          className='relative flex w-full items-center justify-center overflow-hidden'
        >
          <div className='flex-start absolute start-5 top-4 z-40 flex gap-2'>
            {product?.label && product?.label[0]?.status === '1' && (
              <span className='w-fit rounded-md bg-primary px-2 py-1 text-xs font-semibold text-white'>
                {product?.label[0]?.name || ''}
              </span>
            )}
            {price?.discount > 0 && (
              <span dir='ltr' className='w-fit rounded-md bg-orange-500 px-2 py-1 text-xs font-semibold text-white'>
                -{price?.discount || 0}%
              </span>
            )}
            {product?.stock?.is_in_stock === false && (
              <span className='w-fit rounded-md bg-gray-300 px-2 py-1 text-xs font-semibold text-white'>
                {locale === 'en' ? 'Sold Out' : 'نفذت الكمية'}
              </span>
            )}
            {/* <span className='w-fit rounded-md bg-lime-500 px-2 py-1 text-xs font-semibold text-white'>
                  New
                </span> */}
          </div>

          {product?._media_?.image?.at(0)?.image && (
            <div className='relative flex h-full w-full cursor-pointer items-center justify-center overflow-hidden'>
              <Image
                sizes='(min-width: 1700px) 215px, (min-width: 1220px) 12.39vw, (min-width: 1000px) calc(20vw - 25px), (min-width: 780px) calc(27vw - 28px), (min-width: 580px) calc(40vw - 22px), calc(48.85vw - 22px)'
                loading='eager'
                priority={1}
                src={product?._media_?.image?.at(0)?.image || ''}
                className={`w-full border object-contain ${
                  fromGrid
                    ? 'max-h-44 min-h-44 min-w-44 max-w-44 max-sm:max-h-[135px] max-sm:min-h-[135px] max-sm:min-w-36 max-sm:max-w-36'
                    : !isMulti
                      ? 'h-32 sm:h-44'
                      : 'h-36 sm:h-64'
                } rounded-lg`}
                alt=''
              />
            </div>
          )}
        </Link>
        <div className='absolute left-0 top-0 z-[100] hidden h-full w-full items-end justify-center rounded-xl bg-black bg-opacity-60 group-hover:flex max-md:group-hover:hidden'>
          <button
            onClick={() =>
              Context?.dispatch({
                type: 'view',
                payload: product,
              })
            }
            className='mb-2 flex items-center justify-center rounded-md bg-white px-3 py-2'
          >
            <EyeIcon className='text-primary' />
          </button>
        </div>
      </div>
      <div
        className={`${!fromGrid ? 'pt-3' : 'flex flex-1 flex-col gap-1 max-sm:gap-0'}`}
      >
        <div className='min-h-11'>
          <Link
            href={`/product/${product?.rewrite_url}`}
            className='line-clamp-2 text-xs sm:text-sm'
          >
            {product?.name?.at(0) || ''}
          </Link>
        </div>

        <div className={`flex flex-col gap-1 ${!fromGrid ? 'mb-3' : 'mb-0'}`}>
          <span className='whitespace-nowrap text-sm font-bold sm:text-lg'>
            {price?.specialPrice || price?.originalPrice}{' '}
            {locale === 'en' ? 'SAR' : 'ر.س'}
          </span>
          <span
            className={`${
              price?.specialPrice ? 'visible' : 'invisible'
            } whitespace-nowrap text-xs text-[#555555] line-through`}
          >
            {price?.originalPrice} {locale === 'en' ? 'SAR' : 'ر.س'}
          </span>
        </div>

        <div
          className={`flex items-center gap-1 ${
            !fromGrid ? 'mt-2' : 'mt-5 sm:mt-10'
          } text-primary`}
        >
          <AddToCart product={product} />
          <AddtoWishlist product={product} isFav={isFav} />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
